require("./bootstrap");

// Loader
// window.onload = () => {
//     let element = document.getElementById("loader");
//     element.classList.add("hidden");
// };

// ==============================================================
// Handle collapse menu
// ==============================================================

$(window).scroll(function () {
    if ($(this).scrollTop() === 0) {
        $("#navbarToggleExternalContent").css("position", "relative");
    }
    if ($(this).scrollTop() > 0) {
        $("#navbarToggleExternalContent").css("position", "fixed");
        $("#navbarToggleExternalContent").css("z-index", "20");
        $("#navbarToggleExternalContent").css("width", "100%");
    }
});

$(function () {
    "use strict";
    $(".e-content a").attr("target");
    // $(".e-content a").attr("target", "_blank");

    $(".nav-toggler").on("click", function () {
        $("#main-wrapper").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
    });
    $("#toggle-lg").on("click", function () {
        if ($("#main-wrapper").attr("data-sidebartype") === "full") {
            $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
            $(".nav-toggler i").toggleClass("ti-menu");
        } else {
            $("#main-wrapper").attr("data-sidebartype", "full");
            $(".nav-toggler i").toggleClass("ti-menu");
        }
    });

    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").delay(20).show();

    //****************************
    /* This is for the mini-sidebar if width is less then 1170*/
    //****************************
    var setsidebartype = function () {
        var width =
            window.innerWidth > 0 ? window.innerWidth : this.screen.width;
        if (width < 1000) {
            $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
        } else {
            $("#main-wrapper").attr("data-sidebartype", "full");
        }
    };
    $(window).ready(setsidebartype);
    $(window).on("resize", setsidebartype);

    // ==============================================================
    // Auto select left navbar
    // ==============================================================
    var url = window.location + "";
    var path = url.replace(
        window.location.protocol + "//" + window.location.host + "/",
        ""
    );
    var element = $("ul#sidebarnav a").filter(function () {
        return this.href === url || this.href === path; // || url.href.indexOf(this.href) === 0;
    });
    element.parentsUntil(".sidebar-nav").each(function (index) {
        if ($(this).is("li") && $(this).children("a").length !== 0) {
            $(this).children("a").addClass("active");
            $(this).parent("ul#sidebarnav").length === 0
                ? $(this).addClass("active")
                : $(this).addClass("selected");
        } else if (!$(this).is("ul") && $(this).children("a").length === 0) {
            $(this).addClass("selected");
        } else if ($(this).is("ul")) {
            $(this).addClass("in");
        }
    });

    element.addClass("active");
    $("#sidebarnav a").on("click", function (e) {
        if (!$(this).hasClass("active")) {
            // hide any open menus and remove all other classes
            $("ul", $(this).parents("ul:first")).removeClass("in");
            $("a", $(this).parents("ul:first")).removeClass("active");

            // open our new menu and add the open class
            $(this).next("ul").addClass("in");
            $(this).addClass("active");
        } else if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $(this).parents("ul:first").removeClass("active");
            $(this).next("ul").removeClass("in");
        }
    });
    $("#sidebarnav >li >a.has-arrow").on("click", function (e) {
        e.preventDefault();
    });
});

function redirectBasedOnUrl() {
    if (typeof window !== `undefined`) {
        const url = window.location.pathname;
        const parts = url.split("/");
        const lastSegment = parts.pop() || parts.pop();
        // console.log({ lastSegment });
        if (lastSegment.includes("fbclid")) {
            if (!lastSegment.includes("utm_source")) {
                const urlParams = new URLSearchParams(window.location.search);
                const fbclid = urlParams.get("fbclid");
                return (window.location =
                    "/?utm_source=facebook&utm_medium=social_media&utm_campaign=tagwizz_utm&fbclid=" +
                    fbclid);
            }
        }
        switch (lastSegment) {
            case "tiktok":
                return (window.location =
                    "/?utm_source=tiktok&utm_medium=social_media&utm_campaign=tagwizz_utm");
            case "facebook":
                return (window.location =
                    "/?utm_source=facebook&utm_medium=social_media&utm_campaign=tagwizz_utm");
            case "instagram":
                return (window.location =
                    "/?utm_source=instagram&utm_medium=social_media&utm_campaign=tagwizz_utm");
            case "twitter":
                return (window.location =
                    "/?utm_source=twitter&utm_medium=social_media&utm_campaign=tagwizz_utm");
            case "youtube":
                return (window.location =
                    "/?utm_source=youtube&utm_medium=social_media&utm_campaign=tagwizz_utm");
            case "linkedin":
                return (window.location =
                    "/?utm_source=linkedin&utm_medium=social_media&utm_campaign=tagwizz_utm");
            default:
                break;
        }
    }
}

$(window).on("load", function () {
    redirectBasedOnUrl();
});

$( '#add_career_modal, #edit_career_modal, #add_project_modal, #edit_project_modal, #add_post_modal, #edit_post_modal' ).modal( {
	focus: false
} );